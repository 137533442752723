<template>
  <v-card>
    <v-card-title>Ссылки на командные чаты</v-card-title>
    <v-card-text>
      <template v-for="(team, index) of teams">
        <v-divider
          :key="`${index}-separator`"
          class="mt-4 mb-4"
          v-if="index > 0"
        ></v-divider>
        <div class="title" :key="`${index}-title`">{{ team.title }}</div>
        <div class="body" :key="`${index}-link`">
          <a :href="team.link" class="text--primary darken-4">{{
            team.link
          }}</a>
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["item-id", "params"],
  computed: {
    teams() {
      return this.params.teams || [];
    },
  },
};
</script>